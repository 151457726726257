import React from 'react'
import {Box,Text,Heading,Flex} from '@chakra-ui/react'
import MeinSEO from '../components/SEO'
import {StaticImage} from 'gatsby-plugin-image'


const Auschwitz = () => {
  return(
    <>
     <Heading mt="12" pt="1" pl="3" variant="titel">In Auschwitz gab es keine Vögel.<br/> Konzert-Lesung</Heading>
    <Flex  mx="2"  direction={["column","column","row"]}  maxWidth="1300">
  <MeinSEO title="IN AUSCHWITZ GAB ES
KEINE VÖGEL" description="Monika Held liest aus ihrem musikalisch begleitet von Gregor Praml "/>
 <Box pr={["0","0","3"]} width={["100%","100%","55%"]}>

    <StaticImage 
      src="../images/web/teaser-5.jpg"  
      alt="Teaser zur Auschwitz-Veranstaltung"
    />

<Text>
Bald wird es keine Überlebenden des Holocaust mehr geben – und wer erzählt dann? Das haben sich die Autorin Monika Held und der Kontrabassist Gregor Praml gefragt und eine Konzert-­Lesung entwickelt, bei der Lesung, Musik und Originaltöne des Auschwitz-­Über­lebenden Hermann Reineck ineinandergreifen.
</Text>
<Text>Grundlage ist der Roman von Monika Held <span style={{fontStyle:"italic"}}>Der Schrecken verliert sich vor Ort</span>. Im Roman wird die Geschichte des KZ­-Häftlings Heiner und seiner Frau Lena erzählt, die sich beim Frankfurter Auschwitz-­Prozess kennengelernt haben. Er war der Zeuge aus Wien, sie Übersetzerin polnischer Zeugenaussagen. Eine Liebe zwischen Trauma, Unwissen und Missverständnissen.</Text>
</Box>
<Box shadow="md" bg="gray.200" p="3"   flex="1 1">
  
     
  <Heading variant="title"> Buch / Lesung</Heading><Text mt="2" >
Monika Held



  </Text>
  <Heading variant="title"> Musik</Heading>
  <Text>
  Gregor Praml, Kontrabass
  </Text>
  
     <Heading variant="title" >Kaub</Heading><Text>
        Donnerstag, 14. Juli 2022 um 19:30 Uhr <br/> Ev. Kirche – Kirchplatz<br/> 
       
</Text>
<Heading variant="title"> Bacharach</Heading><Text>Freitag, 15. Juli um 19:30 Uhr<br/>
Wernerkapelle, Bacharach
 </Text>
  <Text>(weitere Vorstellungen in Schulen nach Vereinbarung)</Text>
  <Box py="3">
  <Text variant='Bild'>gefördert von:<br/>
  <span style={{fontWeight:500}}>1700 Jahre jüdisches Leben in Deutschland</span>
  </Text>
  <StaticImage src="../images/logos/leben.jpg" width={180} />
 
  </Box>
</Box>
</Flex>
</>
  )
}
 export default Auschwitz


  